import React from "react";
import { Route, Routes } from "react-router-dom";

import manageUser from "./manage-user";
import userRoute from "./user-route";
// export const Routes_ =
//   localStorage.getItem("user_type") == 1 ||
//     ? [...manageUser]
//     : [...manageUser, ...catelogManagement, ...setting];

// export const Routes_ = [...manageUser, ...catelogManagement, ...setting];
const PageRouter = () => {
  // const Routes_ =
  //   localStorage.getItem("user_type") == 1
  //     ? [...manageUser]
  //     : [
  //         ...dashboard,
  //         ...campaignManagement,
  //         ...masterTable,
  //         ...setting,
  //         ...applicationLog,
  //         ...ruleManagement,
  //         // ...ruleBook,
  //         ...advertisementReport,
  //         // ...targetingReport,
  //         // ...keywordManagement,
  //         ...userAccounts,
  //         // ...dayParting
  //       ];

  const Routes_ =
    localStorage.getItem("user_type") == 1 ? [...manageUser] : [...userRoute];

  // const Routes_ = [...manageUser, ...dashboard];

  return (
    <Routes>
      {Routes_?.map((d, i) => {
        return (
          <Route
            key={i}
            path={d?.path}
            element={d?.component}
            exact={d?.exact}
          />
        );
      })}

      {/* <Route path="*" render={() => <div>Error Page</div>} /> */}
      {/* <Route path="*" render={() => <Error />} /> */}
    </Routes>
  );
};

export default PageRouter;
