import { Input, Popover, Select, Table, Tag, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import EsfTable from "../../../component/esf-table";
import Pagination from "../../../component/esf-table/lib/pagination";
import RangePickerESF from "../../../component/range-picker";
import {
  ConvertParams,
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
  metrics_filter,
} from "../../../config";

const PlacementReport = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  // Metrics Filter Start
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([]);
  const [sum_of_all_data, set_sum_of_all_data] = useState({});
  const [budgetAmount, setBudgetAmount] = useState("");

  // const [metrics_filter, set_metrics_filter] = useState({
  //   attributes_dd_data: {
  //     ACoS: "ACOS",
  //     RoAS: "ROAS",
  //     spends: "Spends",
  //     sales: "Total Sales",
  //     cpc: "CPC",
  //     cvr: "Conversion Rate",
  //     unit_ordered: "Orders",
  //     clicks: "Clicks",
  //     impressions: "Impressions",
  //     ctr: "CTR",
  //     ad_sales_percentage: "Ad Sales Percentage",
  //     total_sales: "Total Sales",
  //     total_orders: "Total Orders",
  //     total_acos: "Total ACOS",
  //     SPC: "Selection price and convenience (SPC)",
  //     SPM: "Sales Performance Management (SPM)",
  //   },
  //   operators_dd_data: {
  //     ">=": ">=",
  //     "<=": "<=",
  //     ">": ">",
  //     "<": "<",
  //     "==": "=",
  //   },
  // });

  // Metrics Filter Start
  const [sortFilters, setSortFilters] = useState("");
  const [sortOrderColumns, setSortOrderColumns] = useState("");

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
    // ad_keyword_status: null,
    campaign_status: null,
    advertising_type: null,
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetPlacementReportList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        metric_filter:
          metrics_filter_obj?.length > 0
            ? JSON.stringify(metrics_filter_obj)
            : [],
        order_key: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
    } else {
      setLoading(false);
    }
  };

  const GetPlacementReportList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `advertising/campaign-placements${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
      set_sum_of_all_data(response?.data?.keyword_sum_data);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetPlacementReportList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetPlacementReportList(apiObj);
  };
  const UpdateKeywordBudget = async (body) => {
    message.destroy();
    message.loading("Loading", 0);
    const response = await MakeApiCall(
      "targeting/update-keyword-bid",
      "put",
      body,
      true,
      false
    );
    if (response?.status) {
      message.destroy();
      set_all_filters({
        ...all_filters,
        page: 1,
        "per-page": DefaultPerPage,
      });
      const apiObj = {
        ...all_filters,
        page: 1,
        "per-page": DefaultPerPage,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        metric_filter:
          metrics_filter_obj?.length > 0
            ? JSON.stringify(metrics_filter_obj)
            : [],
        order_key: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      };
      setLoading(true);
      GetPlacementReportList(apiObj);
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const columns = [
    {
      title: "#",
      width: 20,
      fixed: "left",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },
    {
      title: "Placement",
      dataIndex: "placement_classification",
      key: "placement_classification",
      width: 250,
      sortOn: true,
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Campaigns",
      width: 300,
      sortOn: true,
      render: (text) => {
        // if (text?.rule_type === "budget") {
        //   return (
        //     <div style={{ display: "flex", alignItems: "center" }}>
        //       <div className="two-line">
        //         {Object.values(text?.campaign_list?.[0]) || "-"}
        //       </div>

        //       <span
        //         className="ms-2 "
        //         onClick={() => {
        //           setSelectedCamp(text?.campaign_list || []);
        //           setCampaignView(true);
        //         }}
        //       >
        //         <i className="ki-outline ki-eye fs-3 text-dark" />
        //       </span>
        //     </div>
        //   );
        // }

        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {text?.campaign_name}
              </div>
            }
            placement="bottom"
          >
            <span className="popoverActionIconSingleLine">
              {text?.campaign_name}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Campaign Type",
      dataIndex: "campaign_type",
      key: "campaign_type",
      width: 150,
      sortOn: true,
      render: (text) => (
        <Tag
          className="fw-bold"
          color={
            text === "SPONSORED_PRODUCTS"
              ? "gold"
              : text === "SPONSORED_DISPLAY"
              ? "green"
              : text === "SPONSORED_BRAND"
              ? "geekblue"
              : "purple"
          }
          style={{ textTransform: "capitalize" }}
          // bordered={false}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Campaign Targeting Type",
      dataIndex: "campaign_targeting_type",
      key: "campaign_targeting_type",
      width: 250,
      sortOn: true,
    },
    {
      title: "Campaign State",
      dataIndex: "campaign_status",
      key: "campaign_status",
      width: 180,
      sortOn: true,
    },
    // {
    //   title: "Ad Group Name", // Updated title
    //   dataIndex: "ad_group_name",
    //   key: "ad_group_name",
    //   width: 180,
    //   sortOn: true,
    // },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 100,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      sortOn: true,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
    {
      title: "ACoS",
      dataIndex: "acos",
      key: "acos",
      width: 130,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}%</span>,
    },
    {
      title: "Cost-per-click (CPC)",
      dataIndex: "CPC",
      key: "CPC",
      width: 200,
      sortOn: true,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {NumberWithCommas(text) || "0"}
          </span>
        );
      },
    },
    {
      title: "Cost-per-mile(CPM)",
      dataIndex: "CPM",
      key: "CPM",
      width: 200,
      sortOn: true,
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },
    {
      title: "Click-through rate for views (CTR)",
      dataIndex: "CTR",
      key: "CTR",
      width: 300,
      sortOn: true,
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },
    {
      title: "Return on ad spend (ROAS)",
      dataIndex: "RoAS",
      key: "RoAS",
      width: 300,
      sortOn: true,
      render: (text, record) => {
        return (
          <span>
            {selected_currency_sign}
            {NumberWithCommas(text)}
          </span>
        );
      },
    },
    {
      title: "RoAS_per",
      dataIndex: "RoAS_per",
      key: "RoAS_per",
      width: 200,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPC",
      dataIndex: "SPC",
      key: "SPC",
      width: 200,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPM",
      dataIndex: "SPM",
      key: "SPM",
      width: 200,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 150,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Attributed Sales (14 Day)",
      dataIndex: "sales14d",
      key: "sales14d",
      width: 200,
      sortOn: true,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5 ">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="card-toolbar">
                  <div class="d-flex  flex-wrap gap-4">
                    <div className="position-relative">
                      <Input
                        prefix={
                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                        }
                        placeholder="Search by Placement"
                        size="large"
                        className="form-control d-flex w-250px"
                        value={all_filters?.search}
                        onChange={(e) => {
                          set_all_filters({
                            ...all_filters,
                            search: e.target.value,
                          });
                        }}
                        onPressEnter={(e) => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            search: e.target.value,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetPlacementReportList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-1">
                      <RangePickerESF
                        className="ant_common_input w-100  w-250px form-control d-flex"
                        id="ivcsr_date_picker"
                        width={250}
                        size="large"
                        allowClear={false}
                        format={DateFormat}
                        value={[all_filters?.start_date, all_filters?.end_date]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e) => {
                          const filters_ = all_filters;
                          filters_.start_date = e?.[0];
                          filters_.end_date = e?.[1];
                          set_all_filters({
                            ...filters_,
                            page: 1,
                            "per-page": DefaultPerPage,
                          });
                          const apiObj = {
                            ...filters_,
                            page: 1,
                            "per-page": DefaultPerPage,
                            start_date: dayjs(e?.[0]?.$d).format("YYYY-MM-DD"),
                            end_date: dayjs(e?.[1]?.$d).format("YYYY-MM-DD"),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          GetPlacementReportList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-1">
                      <Select
                        className="w-175px"
                        size="large"
                        placeholder="Campaign Type"
                        options={[
                          {
                            label: "Sponsored Product",
                            value: "SP",
                          },
                          {
                            label: "Sponsored Display",
                            value: "SD",
                          },
                          {
                            label: "Sponsored Brand",
                            value: "SB",
                          },
                          { label: "Sponsored TV", value: "ST" },
                        ]}
                        allowClear
                        value={all_filters?.advertising_type}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            advertising_type: e,
                          };
                          set_all_filters(apiObj);
                          setLoading(true);
                          GetPlacementReportList({
                            ...apiObj,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          });
                        }}
                      />
                    </div>
                    <div className="position-relative me-1">
                      <Select
                        className="w-150px"
                        size="large"
                        placeholder="Campaign Status"
                        options={[
                          { label: "PAUSED", value: "PAUSED" },
                          { label: "ENABLED", value: "ENABLED" },
                        ]}
                        allowClear
                        value={all_filters?.campaign_status}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            campaign_status: e,
                          };
                          set_all_filters(apiObj);
                          setLoading(true);
                          GetPlacementReportList({
                            ...apiObj,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          });
                        }}
                      />
                    </div>

                    <div className="position-relative">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                          };
                          set_all_filters(apiObj);
                          setLoading(true);
                          GetPlacementReportList({
                            ...apiObj,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          });
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Popover
                        placement="bottom"
                        trigger="click"
                        open={isOpenMetricsPopover}
                        content={
                          <div className="">
                            {metrics_filter_obj?.map((d, index) => {
                              return (
                                <div className="mt-3 d-flex">
                                  <Select
                                    placeholder="Select Metric"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.attributes_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.attribute}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              attribute: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "200px" }}
                                  />
                                  <Select
                                    className="ms-1"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.operators_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.operator}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              operator: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "100px" }}
                                    placeholder="Select Condition"
                                  />
                                  <Input
                                    style={{ width: "120px" }}
                                    className="ms-1"
                                    value={d?.value}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              value: e.target.value,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    placeholder="Enter Value"
                                  />
                                  {metrics_filter_obj?.length > 1 && (
                                    <span
                                      className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                                      onClick={() => {
                                        const deletedData =
                                          metrics_filter_obj?.filter(
                                            (d, i) => i !== index
                                          );
                                        set_metrics_filter_obj(deletedData);
                                      }}
                                    >
                                      <i className="ki-outline ki-cross fs-2" />
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                            <div className="text-center">
                              <button
                                className="btn btn-light-primary btn-sm mt-5"
                                onClick={() => {
                                  set_metrics_filter_obj([
                                    ...metrics_filter_obj,
                                    {
                                      attribute: null,
                                      operator: null,
                                      value: "",
                                    },
                                  ]);
                                }}
                              >
                                <i className="ki-outline ki-plus fs-2" />
                                Add another
                              </button>
                            </div>

                            <div className="separator separator-dashed my-2"></div>
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-light-danger btn-sm"
                                onClick={() => {
                                  set_metrics_filter_obj([
                                    {
                                      attribute: null,
                                      operator: null,
                                      value: "",
                                    },
                                  ]);
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                {/* <i className="ki-outline ki-cross fs-2" /> */}
                                Clear all
                              </button>
                              <button
                                disabled={
                                  metrics_filter_obj.filter(
                                    (item) =>
                                      !item.attribute ||
                                      !item.operator ||
                                      !item.value
                                  )?.length > 0
                                }
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  const apiObj = {
                                    ...all_filters,
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    metric_filter:
                                      metrics_filter_obj?.length > 0
                                        ? JSON.stringify(metrics_filter_obj)
                                        : [],
                                  };
                                  set_all_filters({
                                    ...all_filters,
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                  });
                                  setLoading(true);
                                  GetPlacementReportList({
                                    ...apiObj,
                                    start_date: dayjs(
                                      all_filters?.start_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    end_date: dayjs(
                                      all_filters?.end_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    metric_filter:
                                      metrics_filter_obj?.length > 0
                                        ? JSON.stringify(metrics_filter_obj)
                                        : [],
                                    order_key: sortOrderColumns,
                                    order_value: sortFilters?.startsWith("-")
                                      ? "SORT_ASC"
                                      : "SORT_DESC",
                                  });
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        }
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            if (metrics_filter_obj?.length === 0) {
                              set_metrics_filter_obj([
                                {
                                  attribute: "",
                                  operator: "",
                                  value: "",
                                },
                              ]);
                            }
                            setIsOpenMetricsPopover(true);
                          }}
                        >
                          <i className="ki-outline ki-plus fs-2" />
                          Metrics{" "}
                        </button>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              <div className="separator separator-dashed my-2"></div>
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  <EsfTable
                    columns={columns?.map((d) => ({
                      ...d,
                      // width: d?.title?.length * 13,
                    }))}
                    sticky={{
                      offsetHeader: "10px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 300px)",
                    }}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    setSortOrderColumns={setSortOrderColumns}
                    onSortChange={(
                      sort,
                      currentPage,
                      pageSize,
                      order_column
                    ) => {
                      set_all_filters({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                      });
                      set_list([]);
                      setLoading(true);
                      GetPlacementReportList({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                        start_date: dayjs(all_filters?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(all_filters?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        order_key: order_column,
                        order_value: sort?.startsWith("-")
                          ? "SORT_ASC"
                          : "SORT_DESC",
                      });
                    }}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            <label className="fw-bold fs-5">
                              Total {totalPage} Placement
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={4}
                          ></Table.Summary.Cell>{" "}
                          <Table.Summary.Cell index={2}>
                            {sum_of_all_data?.clicks || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            {sum_of_all_data?.cost || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            {sum_of_all_data?.ACoS || 0}%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            {selected_currency_sign}
                            {sum_of_all_data?.CPC || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            {selected_currency_sign}
                            {sum_of_all_data?.CPM || 0}
                          </Table.Summary.Cell>{" "}
                          <Table.Summary.Cell index={6}>
                            {sum_of_all_data?.CTR || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            {selected_currency_sign}{" "}
                            {sum_of_all_data?.RoAS || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8}>
                            {" "}
                            {sum_of_all_data?.RoAS_per || 0}%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10}>
                            {sum_of_all_data?.SPC || 0}%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11}>
                            {sum_of_all_data?.SPM || 0}%
                          </Table.Summary.Cell>{" "}
                          <Table.Summary.Cell index={12}>
                            {sum_of_all_data?.impressions || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={13}>
                            {selected_currency_sign}{" "}
                            {sum_of_all_data?.sales || 0}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default PlacementReport;
