import { CloudUploadOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Drawer,
  Dropdown,
  Input,
  message,
  Switch,
  Table,
  Tag,
  theme,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import { GlobalContext } from "../../../../commonContext";
import NoDataTable from "../../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  DownloadFileUsingApi,
  ENDPOINT,
  GetMarketplaceDropdown,
  isNot2000SeriesYear,
  NumberWithCommas,
} from "../../../../config";
import { CampaignWrapper } from "../style";
import CampaignEdit from "./lib/campaign-edit";
import CampaignFilters from "./lib/campaign-filters";
import UpdateBulkCampaign from "./lib/update_bulk_campaign";
const { useToken } = theme;
const CampaignList = (props) => {
  const {
    selected_marketplace,
    set_selected_marketplace,
    marketplace_list,
    set_marketplace_list,
    currency_list,
    set_currency_list,
    selected_currency_sign,
    set_selected_currency_sign,
    setIsVisible,
    setSelctedCampaignData,
  } = props;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);

  const [all_campaign_list, set_all_campaign_list] = useState([]);
  const [list, setList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [isAllCampaign, setIsAllCampaign] = useState(false);
  const [isOpenEditCampaign, setIsOpenEditCampaign] = useState(false);
  const [edit_campaign_data, set_edit_campaign_data] = useState({});
  const [isOpenBulkOperationModal, setIsOpenBulkOperationModal] = useState();
  const [bulkOperationType, setbulkOperationType] = useState();
  const [updatingCampaignStatus, setUpdatingCampaignStatus] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);
  const [columnConfigLoading, setColumnConfigLoading] = useState(false);
  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "ad_type",
    "targeting_settings",
    "state",
    "budget_type",
    "start_date",
    "end_date",
    "budget_amount",
    "impressions",
    "clicks",
    "CTR",
    "spend",
    "CPC",
    "sales",
    "ACoS",
    "RoAS",
    "new_to_brand_orders",
    "unit_ordered",
    "portfolio_name",
    "bid_strategy",
    "detail_page_views",
    "new_to_brand_orders_percentage",
    "new_to_brand_sales",
    "new_to_brand_sales_percentage",
    "viewable_impressions",
  ]);
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false);
  const [sortFilters, setSortFilters] = useState("");
  const [sortOrderColumns, setSortOrderColumns] = useState("");
  const [sum_on_campaign, set_sum_on_campaign] = useState({});
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([
    {
      attribute: "",
      operator: "",
      value: "",
    },
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
    search_key: "",
    ad_type: null,
    campaign_name: "",
    state: null,
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
    no_pagination: true,
    portfolio_id: null,
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };
  console.log(pagination, "pginatio");
  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetCampaignList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      });
      GetPortfolioList(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setLoading(false);
    }
  };

  const GetCampaignList = async (filters) => {
    setList([]);
    const response = await MakeApiCall(
      `advertising/get-all-campaigns-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setSelectedFilterCheckbox(response?.data?.campaign_filter);
      setList(response?.data?.table_data);
      set_all_campaign_list(response?.data?.table_data);
      setTotalPage(response?.data?.pagination?.totalCount);
      set_sum_on_campaign(response?.data?.sum_on_campaign);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetPortfolioList = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SaveCampaignStatus = async (state, campaignList) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      {
        ids: campaignList,
        campaign_status: state,
      },
      true
    );
    if (response?.status) {
      GetCampaignList({
        ...all_filters,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      });
    } else {
      GetCampaignList({
        ...all_filters,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      });
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("status");
          }}
        >
          Update Status
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("budget");
          }}
        >
          Update Budget
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("move_to_portfolio");
          }}
        >
          Move to Portfolio
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("remove_portfolio");
          }}
        >
          Remove from Portfolio
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("end_date");
          }}
        >
          End Date
        </span>
      ),
    },
  ];
  const defaultColumns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllCampaign}
            size="large"
            onChange={(e) => {
              if (e.target.checked) {
                const allCamp = list?.map((d) => d?.campaign_id);
                setSelectedCampaign(allCamp);
              } else {
                setSelectedCampaign([]);
              }
              setIsAllCampaign(e.target.checked);
            }}
          />
        );
      },
      ellipsis: true,
      align: "left",
      fixed: "left",
      width: 80,
      render: (text) => {
        return (
          <>
            <Checkbox
              checked={
                selectedCampaign.filter((a) => a == text?.campaign_id)
                  ?.length == 1
                  ? true
                  : false
              }
              size="large"
              onChange={(e) => {
                const checkboxId = text?.campaign_id;
                setSelectedCampaign((prev) => {
                  if (e?.target?.checked) {
                    return [...prev, checkboxId];
                  } else {
                    return prev.filter(
                      (campaign_id) => campaign_id !== checkboxId
                    );
                  }
                });
              }}
            />
            <Switch
              // disabled={updatingCampaignStatus}
              className="ms-2"
              checked={text?.state === "ENABLED" ? true : false}
              onChange={(e) => {
                setUpdatingCampaignStatus(true);
                SaveCampaignStatus(e ? "ENABLED" : "PAUSED", [
                  text?.campaign_id,
                ]);
                setList((prev) => {
                  return prev.map((item) => {
                    if (item?.campaign_id === text?.campaign_id) {
                      return {
                        ...item,
                        state: text?.state === "ENABLED" ? "PAUSED" : "ENABLED",
                      };
                    }
                    return item;
                  });
                });
              }}
            />
          </>
        );
      },
    },
    // {
    //   title: "Sr. No.",
    //   ellipsis: true,
    //   align: "left",
    //   fixed: "left",
    //   width: 80,
    //   render: (text) => {
    //     return (
    //       <span>
    //         {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text.key}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Campaign Name",
      width: 300,
      dataIndex: "campaign_name",
      key: "campaign_name",
      localSort: true,
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
      render: (text, record) => {
        return (
          <span
            className="fw-bold text-gray-800"
            onClick={() => {
              setSelctedCampaignData(record);
              setIsVisible("ad_group");
            }}
            style={{ cursor: "pointer" }}
          >
            {text}
          </span>
        );
      },
    },
  ];
  const actionColumns = [
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (text) => {
        return (
          <div className="d-flex justify-content-between">
            <div className="mx-1">
              <button
                class="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
                onClick={() => {
                  set_edit_campaign_data(text);
                  setIsOpenEditCampaign(true);
                }}
              >
                <i class="ki-outline ki-pencil fs-2 text-success"></i>
              </button>
            </div>

            {/* <Popconfirm
              title="Are you sure to delete this campaign ?"
              onConfirm={() => {
                // console.log(
                //   [{ campaign_id: text?.campaign_id }],
                //   "[ { campaign_id: text?.campaign_id },]"
                // );
                // message.destroy();
                // message.loading("Deleting...", 0);
                // DeleteWalmartCampaignAction([
                //   { campaign_id: text?.campaign_id },
                // ]);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <div className="mx-1">
                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <i class="ki-outline ki-trash fs-2 text-danger"></i>
                </button>
              </div>
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      title: "Type",
      dataIndex: "ad_type",
      key: "ad_type",
      localSort: true,
      sorter: (a, b) => a.ad_type.localeCompare(b.ad_type),
      width: 150,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "SPONSORED_PRODUCTS"
                ? "gold"
                : text === "SPONSORED_DISPLAY"
                ? "green"
                : text === "SPONSORED_BRAND"
                ? "geekblue"
                : "purple"
            }
            style={{ textTransform: "capitalize" }}
            // bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: (text, _) => {
        return (
          <>
            <Tag
              className="fw-bolder"
              color={
                text === "Proposal"
                  ? "blue"
                  : text === "ENABLED"
                  ? "green"
                  : text === "PAUSED"
                  ? "orange"
                  : text === "COMPLETED"
                  ? "purple"
                  : "error"
              }
            >
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Budget Type",
      dataIndex: "budget_type",
      key: "budget_type",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.budget_type.localeCompare(b.budget_type),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.start_date.localeCompare(b.start_date),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.end_date.localeCompare(b.end_date),
      render: (text) => {
        const year = isNot2000SeriesYear(text);
        return <label>{year ? "No End Date" : text}</label>;
      },
    },
    {
      title: "Targeting Type",
      dataIndex: "targeting_settings",
      key: "targeting_settings",
      localSort: true,
      className: "min-w-150px",
      sorter: (a, b) => {
        const extractNumber = (str) => {
          if (!str) return NaN; // Handle null or undefined
          const match = str.match(/\d+/); // Extract numeric part
          return match ? parseInt(match[0], 10) : NaN;
        };

        const isNumericA = !isNaN(extractNumber(a.targeting_settings));
        const isNumericB = !isNaN(extractNumber(b.targeting_settings));

        if (isNumericA && isNumericB) {
          // Both are alphanumeric with numeric parts
          return (
            extractNumber(a.targeting_settings) -
            extractNumber(b.targeting_settings)
          );
        } else if (isNumericA) {
          return -1; // Prioritize alphanumeric values
        } else if (isNumericB) {
          return 1; // Deprioritize plain text
        } else {
          // Both are plain text, handle null gracefully
          const valA = a.targeting_settings || ""; // Fallback to empty string
          const valB = b.targeting_settings || ""; // Fallback to empty string
          return valA.localeCompare(valB);
        }
      },
    },
    {
      title: "Budget",
      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.budget_amount - b.budget_amount,
      render: (text) => {
        return (
          <span className="">
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.impressions - b.impressions,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.clicks - b.clicks,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Clickthrough rate (CTR)",
      dataIndex: "CTR",
      key: "CTR",
      width: 220,
      localSort: true,
      sorter: (a, b) => a.CTR - b.CTR,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.spend - b.spend,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Cost-per-click (CPC)",
      dataIndex: "CPC",
      key: "CPC",
      width: 200,
      localSort: true,
      sorter: (a, b) => a.CPC - b.CPC,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.sales - b.sales,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Advertising cost of sales (ACOS)",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 270,
      localSort: true,
      sorter: (a, b) => a.ACoS - b.ACoS,
      render: (text) => {
        return <span>{text}%</span>;
      },
    },
    {
      title: "Return on ad spend (ROAS)",
      dataIndex: "RoAS",
      key: "RoAS",
      width: 200,
      localSort: true,
      sorter: (a, b) => a.RoAS - b.RoAS,
      render: (text, record) => {
        return (
          <span>
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },

    {
      title: "New-to-brand (NTB) orders",
      dataIndex: "new_to_brand_orders",
      key: "new_to_brand_orders",
      width: 240,
      localSort: true,
      sorter: (a, b) => a.new_to_brand_orders - b.new_to_brand_orders,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Orders",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
      width: 120,
      localSort: true,
      sorter: (a, b) => a.unit_ordered - b.unit_ordered,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Portfolio",
      dataIndex: "portfolio_name",
      key: "portfolio_name",
      width: 150,
      sorter: (a, b) => {
        const extractNumber = (str) => {
          if (!str) return NaN; // Handle null or undefined
          const match = str.match(/\d+/); // Extract numeric part
          return match ? parseInt(match[0], 10) : NaN;
        };

        const isNumericA = !isNaN(extractNumber(a.portfolio_name));
        const isNumericB = !isNaN(extractNumber(b.portfolio_name));

        if (isNumericA && isNumericB) {
          // Both are alphanumeric with numeric parts
          return (
            extractNumber(a.portfolio_name) - extractNumber(b.portfolio_name)
          );
        } else if (isNumericA) {
          return -1; // Prioritize alphanumeric values
        } else if (isNumericB) {
          return 1; // Deprioritize plain text
        } else {
          // Both are plain text, handle null gracefully
          const valA = a.portfolio_name || ""; // Fallback to empty string
          const valB = b.portfolio_name || ""; // Fallback to empty string
          return valA.localeCompare(valB);
        }
      },
    },
    {
      title: "Campaign bidding strategy",
      dataIndex: "bid_strategy",
      key: "bid_strategy",
      width: 250,
      localSort: true,
      sorter: (a, b) => {
        const extractNumber = (str) => {
          if (!str) return NaN; // Handle null or undefined
          const match = str.match(/\d+/); // Extract numeric part
          return match ? parseInt(match[0], 10) : NaN;
        };

        const isNumericA = !isNaN(extractNumber(a.bid_strategy));
        const isNumericB = !isNaN(extractNumber(b.bid_strategy));

        if (isNumericA && isNumericB) {
          // Both are alphanumeric with numeric parts
          return extractNumber(a.bid_strategy) - extractNumber(b.bid_strategy);
        } else if (isNumericA) {
          return -1; // Prioritize alphanumeric values
        } else if (isNumericB) {
          return 1; // Deprioritize plain text
        } else {
          // Both are plain text, handle null gracefully
          const valA = a.bid_strategy || ""; // Fallback to empty string
          const valB = b.bid_strategy || ""; // Fallback to empty string
          return valA.localeCompare(valB);
        }
      },
    },
    {
      title: "Cost type",
      dataIndex: "cost_type",
      key: "cost_type",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.cost_type.localeCompare(b.cost_type),
    },
    {
      title: "Top-of-search impression share (IS)",
      dataIndex: "top_of_search_impression_share",
      key: "top_of_search_impression_share",
      width: 200,
      localSort: true,
      sorter: (a, b) =>
        a.top_of_search_impression_share.localeCompare(
          b.top_of_search_impression_share
        ),
    },
    {
      title: "Top-of-search bid adjustmentNew FeatureNew",
      dataIndex: "top_of_search_bid_adjustment_new_feature_new",
      key: "top_of_search_bid_adjustment_new_feature_new",
      width: 230,
      localSort: true,
      sorter: (a, b) =>
        a.top_of_search_bid_adjustment_new_feature_new.localeCompare(
          b.top_of_search_bid_adjustment_new_feature_new
        ),
    },
    {
      title: "Detail Page Views (DPV)New FeatureNew",
      dataIndex: "detail_page_views",
      key: "detail_page_views",
      width: 200,
      localSort: true,
      sorter: (a, b) => a.detail_page_views.localeCompare(b.detail_page_views),
    },
    {
      title: "% of orders new-to-brand (NTB)",
      dataIndex: "new_to_brand_orders_percentage",
      key: "new_to_brand_orders_percentage",
      width: 200,
      localSort: true,
      sorter: (a, b) =>
        a.new_to_brand_orders_percentage.localeCompare(
          b.new_to_brand_orders_percentage
        ),
    },
    {
      title: "New-to-brand (NTB) sales",
      dataIndex: "new_to_brand_sales",
      key: "new_to_brand_sales",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.new_to_brand_sales.localeCompare(b.new_to_brand_sales),
    },
    {
      title: "% of sales new-to-brand (NTB)",
      dataIndex: "new_to_brand_sales_percentage",
      key: "new_to_brand_sales_percentage",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.new_to_brand_sales_percentage.localeCompare(
          b.new_to_brand_sales_percentage
        ),
    },
    {
      title: "Viewable impressions",
      dataIndex: "viewable_impressions",
      key: "viewable_impressions",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.viewable_impressions.localeCompare(b.viewable_impressions),
    },
    {
      title: "Video first quartile",
      dataIndex: "video_video_first_quartile",
      key: "video_video_first_quartile",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.video_video_first_quartile.localeCompare(
          b.video_video_first_quartile
        ),
    },
    {
      title: "Video midpoint",
      dataIndex: "video_mid_point",
      key: "video_mid_point",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.video_mid_point.localeCompare(b.video_mid_point),
    },
    {
      title: "Video third quartile",
      dataIndex: "video_third_quartile",
      key: "video_third_quartile",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.video_third_quartile.localeCompare(b.video_third_quartile),
    },
    {
      title: "Video complete",
      dataIndex: "video_complete",
      key: "video_complete",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.video_complete.localeCompare(b.video_complete),
    },
    {
      title: "Video unmute",
      dataIndex: "video_unmute",
      key: "video_unmute",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.video_unmute.localeCompare(b.video_unmute),
    },
    {
      title: "View-through rate (VTR)",
      dataIndex: "video_through_rate",
      key: "video_through_rate",
      width: 150,
      localSort: true,
      sorter: (a, b) =>
        a.video_through_rate.localeCompare(b.video_through_rate),
    },
    {
      title: "Click-through rate for views (CTR)",
      dataIndex: "click_through_rate_for_views",
      key: "click_through_rate_for_views",
      width: 200,
      localSort: true,
      sorter: (a, b) =>
        a.click_through_rate_for_views.localeCompare(
          b.click_through_rate_for_views
        ),
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      width: 150,
      localSort: true,
      sorter: (a, b) => a.CPM.localeCompare(b.CPM),
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },
    // {
    //   title: "Targeting Type",
    //   dataIndex: "targeting_settings",
    //   key: "targeting_settings",
    //   localSort: true,
    //   width: 140,
    //   render: (text) => {
    //     return (
    //       <Tag
    //         className="fw-bolder"
    //         color={
    //           text === "MANUAL" ? "magenta" : text === "AUTO" ? "blue" : ""
    //         }
    //         style={{ textTransform: "capitalize" }}
    //         bordered={false}
    //       >
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  // const onPageNo = (e) => {
  //   set_all_filters({ ...all_filters, page: e });
  //   setLoading(true);
  //   const apiObj = {
  //     ...all_filters,
  //     page: e,
  //     "per-page": all_filters?.["per-page"],
  //     start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
  //     end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
  //     order_column: sortOrderColumns,
  //     order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
  //   };
  //   GetCampaignList(apiObj);
  // };

  // const onPerPage = (e) => {
  //   set_all_filters({ ...all_filters, page: 1, "per-page": e });
  //   setLoading(true);
  //   const apiObj = {
  //     ...all_filters,
  //     page: 1,
  //     "per-page": e,
  //     start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
  //     end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
  //     order_column: sortOrderColumns,
  //     order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
  //   };
  //   GetCampaignList(apiObj);
  // };

  const checkoxOptions = columns?.map((d) => ({
    label: d?.title,
    value: d?.dataIndex,
  }));

  // function downloadXlsxFile(url) {
  //   fetch(url)
  //     .then((response) => {
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       console.log(blob, "-----------------");
  //       if (validateBlobFile(blob)) {
  //         // Create a temporary URL for the blob
  //         const fileURL = URL.createObjectURL(blob);
  //         console.log(fileURL, "blob");

  //         // Create a link element
  //         const link = document.createElement("a");
  //         link.href = fileURL;
  //         link.download = `campaign_list.xlsx`; // Specify the desired filename

  //         // Simulate a click on the link to initiate the download
  //         message.destroy();
  //         link.click();
  //         // Clean up the temporary URL
  //         URL.revokeObjectURL(fileURL);
  //       } else {
  //         message.destroy();
  //         handleBlobError(blob);
  //       }
  //     })
  //     .catch((error) => {
  //       message.destroy();
  //       message.error("Error in file");
  //     });
  // }

  // function validateBlobFile(blob) {
  //   if (
  //     blob.type !==
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  // const handleBlobError = async (blob) => {
  //   try {
  //     console.log(blob, "blob");
  //     const newBlob = await blob.text();
  //     message.destroy();
  //     message.error(newBlob.split(".")?.[0]);
  //   } catch (error) {
  //     return "Invalid File";
  //     // Handle the error accordingly
  //   }
  // };

  const SaveCampaignColumnConfig = async () => {
    message.loading("Saving...", 0);
    const response = await MakeApiCall(
      `advertising/update-campaign-filter`,
      `put`,
      {
        campaign_filter: selectedFilterCheckbox,
      },
      true
    );
    if (response?.status) {
      setLoading(true);
      setColumnConfigLoading(false);
      GetCampaignList({
        ...all_filters,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      });
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      setColumnConfigLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <CampaignWrapper>
      <div className="row">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-0">
              <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                <div className="position-relative me-2">
                  <Input
                    style={{ width: "300px" }}
                    prefix={
                      <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                    }
                    className="form-control d-flex"
                    placeholder="Search by Campaign Name"
                    size="large"
                    value={all_filters?.campaign_name}
                    onChange={(e) => {
                      const apiObj = {
                        ...all_filters,
                        campaign_name: e.target.value,
                      };
                      set_all_filters(apiObj);
                    }}
                    onPressEnter={(e) => {
                      const apiObj = {
                        ...all_filters,
                        start_date: moment(all_filters?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: moment(all_filters?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        campaign_name: e.target.value,
                        order_column: sortOrderColumns,
                        order_value: sortFilters?.startsWith("-")
                          ? "SORT_ASC"
                          : "SORT_DESC",
                      };
                      setLoading(true);
                      GetCampaignList(apiObj);
                    }}
                  />
                </div>
              </div>
              <div className="card-toolbar">
                <div className="position-relative me-2">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                    disabled={selectedCampaign?.length > 0 ? false : true}
                    trigger={["click"]}
                  >
                    <button className="btn btn-light btn-sm ">
                      Update Campaign
                    </button>
                  </Dropdown>
                </div>
                <div className="position-relative me-2">
                  <Dropdown
                    disabled={list === 0 || loading || columnConfigLoading}
                    trigger={["click"]}
                    dropdownRender={() => (
                      // <div className="" style={contentStyle}>
                      <div
                        className=""
                        style={{
                          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.3)",
                          background: "#1C1D22",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              minHeight: "306px",
                              maxHeight: "306px",
                              minWidth: "200px",
                              // border: "1px solid #e6332a8a",
                              borderRadius: "7px",
                              overflow: "auto",
                              padding: "5px 20px",
                            }}
                            className=""
                          >
                            <div className="my-3" />
                            <Checkbox.Group
                              value={selectedFilterCheckbox}
                              className=""
                              options={checkoxOptions}
                              onChange={(value) => {
                                setSelectedFilterCheckbox(value);
                              }}
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            />
                            <div className="separator border-gray-200 mt-3 mb-2" />
                            <div className="mb-2 text-end">
                              <button
                                className="btn btn-primary btn-sm me-4"
                                disabled={columnConfigLoading}
                                onClick={() => {
                                  setColumnConfigLoading(true);
                                  SaveCampaignColumnConfig();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <button className="btn btn-secondary btn-sm">
                      Column Config
                    </button>
                  </Dropdown>
                </div>
                <div
                  className="position-relative me-2"
                  onClick={() => {
                    setIsOpenFilterDrawer(true);
                  }}
                >
                  <button className="btn btn-primary btn-sm">
                    Filters <i class="ki-outline ki-filter fs-2 ms-1"></i>
                  </button>
                </div>
                <div className="position-relative me-2">
                  <button
                    className="btn export-button h-40px"
                    onClick={() => {
                      const data = {
                        ...all_filters,
                        start_date: dayjs(all_filters?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(all_filters?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        marketplace_id: selected_marketplace,
                      };
                      message.loading("Loading...", 0);
                      DownloadFileUsingApi(
                        `${ENDPOINT}campaigns-analytics/export-campaigns${ConvertParams(
                          data
                        )}`,
                        "campaign_list"
                      );

                      // const userId = JSON.parse(
                      //   contextValue?.data?.user_data
                      // )?.id;
                      // console.log(userId, "=userId");
                      // const url = `https://api.bidbison.com/advertising/campaigns-analytics/campaign-report-export?id=${btoa(
                      //   userId
                      // )}${
                      //   all_filters?.ad_type
                      //     ? `&ad_type=${all_filters?.ad_type}`
                      //     : ""
                      // }${
                      //   all_filters?.campaign_name
                      //     ? `&campaign_name=${all_filters?.campaign_name}`
                      //     : ""
                      // }${
                      //   all_filters?.state ? `&state=${all_filters?.state}` : ""
                      // }&marketplace_id=${selected_marketplace}`;
                      // downloadXlsxFile(url);
                    }}
                  >
                    <CloudUploadOutlined />
                    Export
                  </button>
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2">
              {/*begin::Table container*/}
              <div className="table-responsive">
                {loading ? (
                  <Table
                    columns={[
                      ...defaultColumns,
                      ...columns?.filter((d) =>
                        selectedFilterCheckbox?.includes(d?.dataIndex)
                      ),
                      ...actionColumns,
                    ]?.map((d) => {
                      return {
                        ...d,
                        width: d?.width || d?.title?.length * 13,
                      };
                    })}
                    pagination={false}
                    loading={loading}
                  />
                ) : list?.length > 0 ? (
                  <>
                    <Table
                      columns={[
                        ...defaultColumns,
                        ...columns?.filter((d) =>
                          selectedFilterCheckbox?.includes(d?.dataIndex)
                        ),
                        ...actionColumns,
                      ]}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 330px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={pagination}
                      onChange={handleTableChange}
                      summary={() => (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2}>
                              <label className="fw-bold fs-5">
                                Total {sum_on_campaign?.campaign_count}{" "}
                                Campaigns
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={3}
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={2}
                              colSpan={2}
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2}>
                              <label className="fw-bold fs-5">
                                {selected_currency_sign}
                                {sum_on_campaign?.budget}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.impressions}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.clicks}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.CTR} %
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>
                              <label className="fw-bold fs-5">
                                {selected_currency_sign}
                                {sum_on_campaign?.spend}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>
                              <label className="fw-bold fs-5">
                                {selected_currency_sign} {sum_on_campaign?.CPC}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9}>
                              <label className="fw-bold fs-5">
                                {selected_currency_sign}
                                {sum_on_campaign?.sales}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={10}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.ACoS} %
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={11}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.RoAS} %
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={12}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.new_to_brand_orders}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={13}>
                              <label className="fw-bold fs-5">
                                {sum_on_campaign?.unit_ordered}
                              </label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={13}
                              colSpan={2}
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell index={14}></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )}
                    />
                    {/* <EsfTable
                      columns={[
                        ...defaultColumns,
                        ...columns?.filter((d) =>
                          selectedFilterCheckbox?.includes(d?.dataIndex)
                        ),
                        ...actionColumns,
                      ]}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 330px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={true}
                      setSortFilters={setSortFilters}
                      sortFilters={sortFilters}
                      setSortOrderColumns={setSortOrderColumns}
                      onSortChange={(
                        sort,
                        currentPage,
                        pageSize,
                        order_column
                      ) => {
                        set_all_filters({
                          ...all_filters,
                          page: currentPage,
                          "per-page": pageSize,
                        });
                        // setList([]);
                        // setLoading(true);
                        // GetCampaignList({
                        //   ...all_filters,
                        //   page: 1,
                        //   "per-page": DefaultPerPage,
                        //   start_date: moment(all_filters?.start_date?.$d).format(
                        //     "YYYY-MM-DD"
                        //   ),
                        //   end_date: moment(all_filters?.end_date?.$d).format(
                        //     "YYYY-MM-DD"
                        //   ),
                        //   order_column: order_column,
                        //   order_value: sort?.startsWith("-")
                        //     ? "SORT_ASC"
                        //     : "SORT_DESC",
                        // });
                      }}
                    /> */}
                  </>
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
              </div>
              {/* <Pagination
                loading={loading || list?.length === 0}
                pageSize={all_filters?.["per-page"]}
                page={all_filters?.page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              /> */}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        {/*end::Col*/}
      </div>
      {isOpenEditCampaign && (
        <CampaignEdit
          show={isOpenEditCampaign}
          data={edit_campaign_data}
          portfolioList={portfolioList}
          selected_marketplace={selected_marketplace}
          selected_currency_sign={selected_currency_sign}
          onHide={() => {
            setIsOpenEditCampaign(false);
            GetCampaignList({
              ...all_filters,
              order_column: sortOrderColumns,
              order_value: sortFilters?.startsWith("-")
                ? "SORT_ASC"
                : "SORT_DESC",
              start_date: moment(all_filters?.start_date?.$d).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
            });
            set_edit_campaign_data({});
          }}
        />
      )}

      {isOpenBulkOperationModal && (
        <UpdateBulkCampaign
          show={isOpenBulkOperationModal}
          operationType={bulkOperationType}
          selectedCampaign={selectedCampaign}
          portfolioList={portfolioList}
          setLoading={setLoading}
          onHide={() => {
            setIsOpenBulkOperationModal(false);
          }}
          onSuccess={() => {
            setLoading(true);
            GetCampaignList({
              ...all_filters,
              order_column: sortOrderColumns,
              order_value: sortFilters?.startsWith("-")
                ? "SORT_ASC"
                : "SORT_DESC",
              start_date: moment(all_filters?.start_date?.$d).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
            });
            setbulkOperationType("");
            setSelectedCampaign([]);
            setIsOpenBulkOperationModal(false);
          }}
        />
      )}

      <Drawer
        title="Filters"
        onClose={() => {
          setIsOpenFilterDrawer(false);
        }}
        maskClosable={false}
        open={isOpenFilterDrawer}
        width={"450px"}
        footer={
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                const filteredMetrics = metrics_filter_obj.filter(
                  (item) =>
                    item.attribute !== null &&
                    item.operator !== null &&
                    item.value !== ""
                );
                console.log(
                  filteredMetrics,
                  metrics_filter_obj,
                  "-filteredMetrics"
                );

                set_metrics_filter_obj(filteredMetrics);
                const apiObj = {
                  ...all_filters,
                  page: 1,
                  "per-page": DefaultPerPage,
                  start_date: moment(all_filters?.start_date?.$d).format(
                    "YYYY-MM-DD"
                  ),
                  end_date: moment(all_filters?.end_date?.$d).format(
                    "YYYY-MM-DD"
                  ),
                  order_column: sortOrderColumns,
                  order_value: sortFilters?.startsWith("-")
                    ? "SORT_ASC"
                    : "SORT_DESC",
                  metric_filter: JSON.stringify(filteredMetrics) || [],
                  marketplace_id: selected_marketplace,
                };
                setLoading(true);
                GetCampaignList(apiObj);
                setIsOpenFilterDrawer(false);
              }}
            >
              Save
            </button>
          </div>
        }
      >
        <CampaignFilters
          set_selected_currency_sign={set_selected_currency_sign}
          currency_list={currency_list}
          all_filters={all_filters}
          set_all_filters={set_all_filters}
          marketplace_list={marketplace_list}
          selected_marketplace={selected_marketplace}
          set_selected_marketplace={set_selected_marketplace}
          setLoading={setLoading}
          GetApiCall={(data) => {
            setList([]);
            setSortOrderColumns("");
            setSortFilters("");
            set_metrics_filter_obj([
              {
                attribute: null,
                operator: null,
                value: "",
              },
            ]);
            GetCampaignList({
              ...data,
              order_column: sortOrderColumns,
              order_value: sortFilters?.startsWith("-")
                ? "SORT_ASC"
                : "SORT_DESC",
              start_date: moment(all_filters?.start_date?.$d).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
              marketplace_id: selected_marketplace,
            });
          }}
          setIsOpenFilterDrawer={setIsOpenFilterDrawer}
          metrics_filter_obj={metrics_filter_obj}
          set_metrics_filter_obj={set_metrics_filter_obj}
          portfolioList={portfolioList}
        />
      </Drawer>
    </CampaignWrapper>
  );
};

export default CampaignList;
