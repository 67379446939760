import AdvertisementReport from "../modules/pages/advertisement-report/advertisement-report";
import CentralLog from "../modules/pages/application-logs/central-log";
import SystemErrorLog from "../modules/pages/application-logs/system-error-log";
import AdGroupList from "../modules/pages/campaign-analytics-details/ad_group";
import ProductAdsList from "../modules/pages/campaign-analytics-details/products_ads";
import Campaign from "../modules/pages/campaign-management";
import Dashboard from "../modules/pages/dashboard/dashboard";
import Dayparting from "../modules/pages/day-parting/day-parting";
import KeywordTargeting from "../modules/pages/keyword-management/keyword-targeting/keyword-targeting";
import ProductDetails from "../modules/pages/master-table/product-details";
import RecommendationAndAlerts from "../modules/pages/master-table/recommendation-alerts";
import PlacementReport from "../modules/pages/placement/placement";
import Portfolio from "../modules/pages/portfolio";
import ProductAnalytics from "../modules/pages/product-analytics";
import RuleBook from "../modules/pages/rule-book/rule-book";
import Setting from "../modules/pages/setting";
import Keyword from "../modules/pages/targeting-report/keyword-report";
import KeywordSearchTermsReport from "../modules/pages/targeting-report/keyword-search-term-report";
import ProductAudienceTargeting from "../modules/pages/targeting-report/product-audience-targeting";
import ProductTargetSearchterm from "../modules/pages/targeting-report/product-target-search-term";
import UserAccounts from "../modules/pages/user-accounts/user-account";
export default [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: true,
  },
  {
    path: "/user-accounts",
    component: <UserAccounts />,
    exact: true,
  },
  {
    path: "/campaign",
    component: <Campaign />,
    exact: true,
  },
  {
    path: "/portfolio",
    component: <Portfolio />,
    exact: true,
  },
  {
    path: "/product-ads-list",
    component: <ProductAdsList />,
    exact: true,
  },
  {
    path: "/product-list",
    component: <ProductAnalytics />,
    exact: true,
  },
  {
    path: "/ad-groups",
    component: <AdGroupList />,
    exact: true,
  },
  {
    path: "/keyword-report",
    component: <Keyword />,
    exact: true,
  },
  {
    path: "/keyword-search-terms-report",
    component: <KeywordSearchTermsReport />,
    exact: true,
  },
  {
    path: "/product-audience-targeting",
    component: <ProductAudienceTargeting />,
    exact: true,
  },
  {
    path: "/product-target-search-term",
    component: <ProductTargetSearchterm />,
    exact: true,
  },
  {
    path: "/product-details",
    component: <ProductDetails />,
    exact: true,
  },
  {
    path: "/recommendation-alerts",
    component: <RecommendationAndAlerts />,
    exact: true,
  },
  {
    path: "/callbackads",
    component: <Setting />,
    exact: true,
  },
  {
    path: "/callbacksp",
    component: <Setting />,
    exact: true,
  },
  {
    path: "/setting",
    component: <Setting />,
    exact: true,
  },
  //   {
  //     path: "/reset-password",
  //     component: <Setting />,
  //     exact: true,
  //   },
  {
    path: "/profile",
    component: <Setting />,
    exact: true,
  },
  {
    path: "/application-logs/central-log",
    component: <CentralLog />,
    exact: true,
  },
  {
    path: "/application-logs/system-error-log",
    component: <SystemErrorLog />,
    exact: true,
  },
  {
    path: "/automation/rule-book",
    component: <RuleBook />,
    exact: true,
  },
  {
    path: "/automation/day-parting",
    component: <Dayparting />,
    exact: true,
  },
  {
    path: "/automation/keyword-harvesting",
    component: <KeywordTargeting />,
    exact: true,
  },
  // {
  //   path: "/keyword-management/keyword",
  //   component: <Keywords />,
  //   exact: true,
  // },
  {
    path: "/advertisement-report",
    component: <AdvertisementReport />,
    exact: true,
  },
  {
    path: "/placement",
    component: <PlacementReport />,
    exact: true,
  },
];
