import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import {
  DatePicker,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Tag,
} from "antd";
import { DateFormat, DisplayNotification } from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
import dayjs from "dayjs";
import moment from "moment";

export default function (props) {
  const {
    show,
    onHide,
    operationType,
    selectedAdGroup,
    setLoading,
    onSuccess,
    selected_marketplace,
  } = props;
  const [sending, setSending] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("PAUSED");

  const UpdateBulkStatusAction = async (data) => {
    const response = await MakeApiCall(
      `advertising/update-ad-group`,
      `put`,
      data,
      true
    );
    if (response?.status) {
      setLoading(true);
      setTimeout(() => {
        setSending(false);
        onSuccess();
        DisplayNotification("success", "Success", response?.message);
      }, [5000]);
    } else {
      setSending(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Update Ad Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {operationType === "status" && (
            <div className="row">
              <div className="col-12 mb-4">
                <label className="fw-bold fs-6">Ad Group Status</label>
              </div>
              <div className="col-12 mb-7">
                <Radio.Group
                  name="radiogroup"
                  //   defaultValue={"PAUSED"}
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <Radio value={"ENABLED"}>
                    <Tag className="fw-bolder" color={"green"}>
                      ENABLED
                    </Tag>
                  </Radio>
                  <Radio value={"PAUSED"}>
                    <Tag className="fw-bolder" color={"orange"}>
                      PAUSED
                    </Tag>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary fs-7"
          disabled={sending}
          onClick={() => {
            const spList = selectedAdGroup?.filter(
              (keyword) => keyword?.campaign_type === "SPONSORED_PRODUCTS"
            );
            const sbList = selectedAdGroup?.filter(
              (keyword) => keyword?.campaign_type === "SPONSORED_BRANDS"
            );
            const sdList = selectedAdGroup?.filter(
              (keyword) => keyword?.campaign_type === "SPONSORED_DISPLAY"
            );
            const data = {
              marketplace_id: selected_marketplace,
              sp_adGroup_id: spList?.map((d) => d?.ad_group_id)?.join(", "),
              sb_adGroup_id: sbList?.map((d) => d?.ad_group_id)?.join(", "),
              sd_adGroup_id: sdList?.map((d) => d?.ad_group_id)?.join(", "),
            };
            if (operationType === "status") {
              data.state = selectedStatus;
            }

            setSending(true);
            console.log(data, "-----data");
            UpdateBulkStatusAction(data);
          }}
        >
          Update
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
